import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getOdgovori} from "../api/formularApi";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    Box,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid, LinearProgress, Paper,
    TextField,
    Typography
} from "@mui/material";
import slika from "../logo.svg";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import Copyright from "../components/Copyright";
import {DataGrid} from "@mui/x-data-grid";

const AnalitikaPage = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['getOdgovori'],
        queryFn: () => getOdgovori()
    });
    const defaultTheme = createTheme();

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'ime',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'prezime',
            headerName: 'Surname',
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'organizacija',
            headerName: 'Company/Organization',
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'email',
            headerName: 'E-mail',
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: 'registracija',
            headerName: 'Registration',
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.registracija == null || params.row.registracija == "") {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No registration!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 16}}>{params.row.registracija}</span>
                    );
                }

            }
        },
        {
            field: "imggi",
            headerName: "IMGGE",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.imggi == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "classado",
            headerName: "Classado",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.classado == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "probi",
            headerName: "PROBI",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.probi == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "plt_hs",
            headerName: "PLT Health Solution",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.plt_hs == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "crop_science",
            headerName: "Crop Science",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.crop_science == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "wacker_chemie",
            headerName: "Wacker Chemie",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.wacker_chemie == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "circe",
            headerName: "Circe",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.circe == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "pharma_nutra",
            headerName: "PharmaNutra",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.pharma_nutra == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
        {
            field: "fabrika",
            headerName: "Phytonet manufacturing site visit",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.fabrika == 1) {
                    return (
                        <span style={{color: 'green', fontWeight: 'bold', fontSize: 14}}>Yes!</span>
                    );
                } else {
                    return (
                        <span style={{color: 'red', fontWeight: 'bold', fontSize: 14}}>No!</span>
                    );
                }
            }
        },
    ];
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xl" sx={{p: 4, borderRadius: 10, mt: 4, mb: 4, backgroundColor: '#cfe2f3',}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                            <Typography sx={{textAlign: 'center', fontWeight: 'bold', fontSize: 24}}>
                                List of answers
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{p: 5, display: "flex", flexDirection: "column", height: 500}}>
                            {isLoading ? (
                                <LinearProgress/>
                            ) : (
                                <DataGrid
                                    rows={
                                        // @ts-ignore
                                        isLoading ? [] : data?.data.odgovori
                                    }
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default AnalitikaPage;