import axios from 'axios';

const path = 'https://phytofarm-api.kapromes.com/api';

//const path = 'http://127.0.0.1:8000/api';

export const insertOdgovor = async (formData) => {
    const response = axios.post(path + '/insert-odgovor', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}
export const getOdgovori = async () => {
    const response = axios.get(path + '/get-odgovori/cGh5dG9BbmFsaXRpa2E', {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}