import React from 'react';
import {Link, Typography} from "@mui/material";

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'All right reserved. © '}
            <Link color="inherit" href="https://phytonet.com/" target="_blank" rel="noopener noreferrer">
                PHYTONET
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default Copyright;