import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import slika from '../logo.svg'
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {
    Box,
    CssBaseline,
    Button,
    Typography,
    Container,
    Checkbox,
    FormControlLabel, TextField, Snackbar, Alert
} from "@mui/material";
import Copyright from "../components/Copyright";
import {useMutation} from "@tanstack/react-query";
import {insertOdgovor} from "../api/formularApi";
import image from '../assets/header.png'

const FormularPage = () => {
    const navigate = useNavigate();
    const [ime, setIme] = useState(null);
    const [prezime, setPrezime] = useState(null);
    const [email, setEmail] = useState(null);
    const [organizacija, setOrganizacija] = useState(null);
    const [fabrika, setFabrika] = useState(false);
    const [privacy, setPrivacy] = useState(false);

    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const defaultTheme = createTheme();

    const mutation = useMutation({
        mutationFn: (formData) => insertOdgovor(formData),
        onSuccess: (response) => {
            setSuccess(response.data.message);
            setError("");
            setOpen(true);
        },
        onError: (err) => {
            setSuccess("");
            setError(err.response.data.message);
            setOpen(true);
        }
    });

    const handleSubmit = (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);

            if (ime == null) {
                setSuccess("");
                setError("Your name is required!");
                setOpen(true);
                return;
            }

            if (prezime == null) {
                setSuccess("");
                setError("Your surname is required!");
                setOpen(true);
                return;
            }

            if (email == null) {
                setSuccess("");
                setError("Your email is required!");
                setOpen(true);
                return;
            }

            if (organizacija == null) {
                setSuccess("");
                setError("Your organization is required!");
                setOpen(true);
                return;
            }

            if (privacy == false) {
                setSuccess("");
                setError("You need to accept our privacy policy!");
                setOpen(true);
                return;
            }
            formData.append('kompanije', checked);
            formData.append('fabrika', fabrika);
            formData.append('privacy', privacy);

            mutation.mutate(formData);
    };

    const handleChangeFabrika = (event) => {
        setFabrika(event.target.checked);
    };
    const handleChangePolicy = (event) => {
        setPrivacy(event.target.checked);
    };

    const [checked, setChecked] = React.useState([false, false, false, false, false, false, false, false]);

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked,
            event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
    };

    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], event.target.checked, checked[2], checked[3], checked[4], checked[5], checked[6], checked[7]]);
    };

    const handleChange4 = (event) => {
        setChecked([checked[0], checked[1], event.target.checked, checked[3], checked[4], checked[5], checked[6], checked[7]]);
    };

    const handleChange5 = (event) => {
        setChecked([checked[0], checked[1], checked[2], event.target.checked, checked[4], checked[5], checked[6], checked[7]]);
    };

    const handleChange6 = (event) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], event.target.checked, checked[5], checked[6], checked[7]]);
    };

    const handleChange7 = (event) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], event.target.checked, checked[6], checked[7]]);
    };

    const handleChange8 = (event) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], event.target.checked, checked[7]]);
    };

    const handleChange9 = (event) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], event.target.checked]);
    };

    const elements = (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
            <FormControlLabel
                label={<span>Ivana Strahinić, HiRaDophilus<sup>®</sup> and CANDBERROL<sup>®</sup>, IMGGE</span>}
                control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
            />
            <FormControlLabel
                label={<span>Per Rehné, Bimuno<sup>®</sup>, Clasado Biosciences</span>}
                control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
            />
            <FormControlLabel
                label="Daniel Hovel Hansen, Probiotics, Probi"
                control={<Checkbox checked={checked[2]} onChange={handleChange4} />}
            />
            <FormControlLabel
                label={<span>Rodolphe Hang, AprèsFlex<sup>®</sup>, PLT Health Solutions</span>}
                control={<Checkbox checked={checked[3]} onChange={handleChange5} />}
            />
            <FormControlLabel
                label={<span>Greg Cumberford, Ahiflower<sup>®</sup>, Natures Crops</span>}
                control={<Checkbox checked={checked[4]} onChange={handleChange6} />}
            />
            <FormControlLabel
                label={<span>Benjamin Bath, CAVAQ10<sup>®</sup> and CAVACURMIN<sup>®</sup>, Wacker Chemie</span>}
                control={<Checkbox checked={checked[5]} onChange={handleChange7} />}
            />
            <FormControlLabel
                label={<span>David Vallet, PTEROVITA<sup>®</sup> cocrystal, CIRCE Scientific </span>}
                control={<Checkbox checked={checked[6]} onChange={handleChange8} />}
            />
            <FormControlLabel
                label={<span>Francesco Agostino Casamenti, Sucrosomial<sup>®</sup> Technology, PharmaNutra</span>}
                control={<Checkbox checked={checked[7]} onChange={handleChange9} />}
            />
        </Box>
    );

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 4,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            backgroundColor: '#cfe2f3',
                            borderRadius: 6
                        }}
                    >
                        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                            <div>
                                {success == "" ? null : (
                                <Alert
                                    onClose={handleClose}
                                    severity="success"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    <span>{success}</span>
                                </Alert>
                            )}
                            {error == "" ? null : (
                                <Alert
                                    onClose={handleClose}
                                    severity="error"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    <span>{error}</span>
                                </Alert>
                            )}
                            </div>
                        </Snackbar>
                        <Box sx={{justifyContent: 'center', textAlign: 'center'}}>
                            <img src={image} alt="#" style={{maxWidth: '100%'}}/>
                        </Box>

                        <Box sx={{mt: 2, backgroundColor: '#fff', p: 2, textAlign: 'left',}}>
                            <span style={{fontSize: 18, fontWeight: 'bold', color: "#28235b"}}>We kindly ask you to register for the Conference by filling in the form below by Friday, August 30, 2024.</span>
                            <br/>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <hr/>
                            <TextField helperText="Enter your first name" sx={{mt: 2}} value={ime}
                                       onChange={(e) => setIme(e.target.value)} required fullWidth label="First name"
                                       name="name" id="name" variant="outlined"/>
                            <TextField helperText="Enter your family name" sx={{mt: 2}} value={prezime}
                                       onChange={(e) => setPrezime(e.target.value)} required fullWidth label="Family name"
                                       name="surname" id="surname" variant="outlined"/>
                            <TextField helperText="Enter your email" sx={{mt: 2}} value={email}
                                       onChange={(e) => setEmail(e.target.value)} required fullWidth label="Email"
                                       name="email" id="email" variant="outlined"/>
                            <TextField helperText="Enter your company / organization" sx={{mt: 2}} value={organizacija}
                                       onChange={(e) => setOrganizacija(e.target.value)} required fullWidth
                                       label="Company / Organization name" name="organization" id="organization"
                                       variant="outlined"/>
                            <TextField
                                helperText="If you are planning to attend the Conference at the Science Technology Park by car, please provide your vehicle's registration number so we can inform the administrative office."
                                sx={{mt: 2}} fullWidth label="Car Registration Number" name="registracija"
                                id="registracija" variant="outlined"/>
                            <hr/>
                            <div style={{marginTop: 10}}>
                                <span style={{fontSize: 18, fontWeight: 'bold', color: "#28235b"}}>Schedule one-on-one meetings with the speakers:</span>
                                <br/>
                                <FormControlLabel
                                    label="Select all"
                                    control={
                                        <Checkbox
                                            checked={checked[0] && checked[1] && checked[2] && checked[3] && checked[4] && checked[5]
                                                && checked[6] && checked[7] && checked[8]}
                                            onChange={handleChange1}
                                        />
                                    }/>
                                {elements}
                            </div>
                            <hr/>
                            <FormControlLabel fullWidth sx={{mt: 2}} control={<Checkbox
                                checked={fabrika}
                                onChange={handleChangeFabrika}
                                inputProps={{'aria-label': 'controlled'}}
                                name="fabrika_check"
                                id="fabrika_check"
                            />}
                                              label={<span style={{fontSize: 18, fontWeight: 'bold', color: "#28235b"}}>Please let us know if you are interested in visiting the new Phytonet manufacturing site in Šimanovci on Wednesday, September 18, 2024.</span>}/>
                            <FormControlLabel fullWidth sx={{mt: 2}} control={<Checkbox
                                checked={privacy}
                                onChange={handleChangePolicy}
                                inputProps={{'aria-label': 'controlled'}}
                                name="privacy_policy"
                                id="privacy_policy"
                            />} label={
                                <div>
                                    <span>
                                        I accept your <Button onClick={() => navigate('/privacy-policy')}
                                                              variant="text">privacy policy.</Button> *
                                    </span>
                                </div>
                            }/>
                            <Button
                                type="submit"
                                fullWidth={true}
                                disabled={!privacy}
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                endIcon={<CheckTwoToneIcon/>}
                            >
                                Submit your answer
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>
    );
};

export default FormularPage;