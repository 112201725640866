import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import FormularPage from "./pages/FormularPage";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AnalitikaPage from "./pages/AnalitikaPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const AppRoutes = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route index path="" element={<FormularPage/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                <Route path="/analitika/cGh5dG9BbmFsaXRpa2E" element={<AnalitikaPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <AppRoutes></AppRoutes>
        </QueryClientProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
